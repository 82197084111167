import { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, ProfilePic, Modal } from '@collegebacker/backer-ui/ui'

import { NumberFormat, removeBodyStyleAttributes } from 'utils'
import { getFrequencyLabel } from 'utils/helpers'
import { getQuery } from 'hooks'
import { getDateLabel } from 'modules/contribution/components/SummaryHeader'
import { SessionContext } from 'context'
import transaction from 'static/img/transaction.svg'
import styles from './ContributionSuccessModal.module.scss'

const ContributionSuccessModal = ({
  contribution,
  showConfirmationModal,
  setShowConfirmationModal,
  onClose
}: {
  contribution: any
  showConfirmationModal: boolean
  setShowConfirmationModal: (arg0: boolean) => void
  onClose?: any
}) => {
  const history = useHistory()
  const context = useContext(SessionContext)
  const userEmail = context.user?.email
  const {
    amount,
    frequency,
    photoUrl,
    createdAsGift,
    activationDate,
    firstDay,
    secondDay
  } = contribution || {}
  const queryParams = getQuery()
  useEffect(() => {
    const html = document.querySelector('html')
    html && html.classList.add('scroll-lock')
    return () => {
      html?.classList.remove('scroll-lock')
    }
  }, [])

  return (
    <Modal
      onCloseClick={() => {
        if (queryParams) {
          queryParams.delete('type')
          history.replace({
            search: queryParams.toString()
          })
        }
        if (onClose) onClose()
        removeBodyStyleAttributes()
        setShowConfirmationModal(false)
      }}
      isOpen={showConfirmationModal}
      isBottomSheet
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 className="typo-app-title-medium">{getTitle(contribution)}</h1>
          <p className={styles.subtitle}>{getSubtitle(contribution)}</p>
        </div>
        <div className={styles.summarySection}>
          <div>
            <p className={styles.title}>
              {NumberFormat.currencyFromCents(amount, false, true)}{' '}
              {getFrequencyLabel(frequency)}{' '}
            </p>
            <p>{getPaymentText(contribution)}</p>
            <p className={styles.dateLabel}>
              {getDateLabel({ frequency, activationDate, firstDay, secondDay })}
            </p>
          </div>
          <ProfilePic
            imageSrc={photoUrl}
            name={contribution.fundName}
            className={styles.image}
          />
        </div>
        <div className={styles.section}>
          <div className={styles.flexContainer}>
            <img src={transaction} alt="" className={styles.transactionImg} />
            {userEmail && (
              <p>
                We have sent a copy of your receipt to &nbsp;
                <span className={styles.email}>{userEmail}</span>.
              </p>
            )}
            {!userEmail && <p>'We have emailed you a copy of your receipt.'</p>}
          </div>
          <Button
            className={styles.buttonFullWidth}
            mode="primary"
            label={`${createdAsGift ? 'Done' : 'View your fund'}`}
            onClick={() => {
              setShowConfirmationModal(false)
              document.querySelector('html')!.classList.remove('scroll-lock')
              if (onClose) onClose()
              removeBodyStyleAttributes()
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

function getTitle({
  createdAsGift,
  firstContribution,
  wasUpdated
}: {
  createdAsGift?: boolean
  firstContribution?: boolean
  wasUpdated?: boolean
}) {
  if (wasUpdated) return 'Contribution updated!'
  if (createdAsGift) return 'Thanks for being a backer!'
  if (firstContribution) return 'Your fund is growing!'
  return 'Payment successful!'
}

function getSubtitle({
  createdAsGift,
  firstContribution
}: {
  createdAsGift?: boolean
  firstContribution?: boolean
}) {
  if (createdAsGift)
    return `Your gift will make a big difference for a kid you love. 🎉`
  if (firstContribution) return `You've made your first contribution. 🎉`
  return 'Hooray! 🎉'
}

function getPaymentText({
  createdAsGift,
  firstContribution,
  fundName,
  recipientName
}: {
  createdAsGift?: boolean
  firstContribution?: boolean
  fundName: string
  recipientName: string
}) {
  if (createdAsGift || firstContribution)
    return `Contribution to ${fundName || recipientName}`
  return `Payment to ${recipientName || fundName}`
}

export default ContributionSuccessModal
